import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import core from "@/core";
import store from "@/store";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Login from "@/views/Login.vue";
import Logout from "@/views/Logout.vue";
import Register from "@/views/Register.vue";
import Home from "@/views/Home.vue";
import Notification from "@/views/Notification.vue";
import Notice from "@/views/notice/Index.vue";
import NoticeView from "@/views/notice/View.vue";
import Event from "@/views/event/Index.vue";
import Settings from "@/views/settings/Index.vue";
import Payment from "@/views/settings/Payment.vue";
import UserMeInfo from "@/views/settings/UserMeInfo.vue";
import UserMePassword from "@/views/settings/UserMePassword.vue";
import UserCompany from "@/views/settings/UserCompany.vue";
import EventAddEdit from "@/views/event/AddEdit.vue";
import EventView from "@/views/event/View.vue";
import { Role, UserModel } from "@/models/user/user.model";
import Constant from "@/store/constant";
import { AppBarMenu, ApprovalType, CompanyRole } from "@/types";
import EventRoulette from "@/views/event/Roulette.vue";
import SpecialPriceLink from "@/views/event/SpecialPriceLink.vue";
import Estimate from "@/views/estimate/Index.vue";
import EstimateView from "@/views/estimate/View.vue";
import EstimateAddEdit from "@/views/estimate/AddEdit.vue";
import EstimateSearch from "@/views/estimate/Search.vue";
import CounselEstimate from "@/views/estimate/Counsel.vue";
import CounselEstimateDownload from "@/views/estimate/CounselDownload.vue";
import EventRouletteWinnerSearch from "@/views/event/RouletteWinnerSearch.vue";
import MgmtUser from "@/views/mgmt/user/Index.vue";
import MgmtUserSearch from "@/views/mgmt/user/Search.vue";
import MgmtUserView from "@/views/mgmt/user/View.vue";
import MgmtPaymentSms from "@/views/mgmt/payment-sms/Index.vue";
import MgmtTeam from "@/views/mgmt/team/Index.vue";
import MgmtTeamView from "@/views/mgmt/team/View.vue";
import MgmtTeamAddEdit from "@/views/mgmt/team/AddEdit.vue";
import MgmtCategory from "@/views/mgmt/category/Index.vue";
import MgmtWorkScope from "@/views/mgmt/work-scope/Index.vue";
import MgmtWorkScopeAddEdit from "@/views/mgmt/work-scope/AddEdit.vue";
import MgmtSpecialPrice from "@/views/mgmt/special-price/Index.vue";
import MgmtSpecialPriceAddEdit from "@/views/mgmt/special-price/AddEdit.vue";
import MgmtUnitPrice from "@/views/mgmt/unit-price/Index.vue";
import MgmtUnitPriceAddEdit from "@/views/mgmt/unit-price/AddEdit.vue";
import MgmtUnitPriceSearch from "@/views/mgmt/unit-price/Search.vue";
import MgmtCancellationPolicy from "@/views/mgmt/cancellation-policy/Index.vue";
import MgmtPlace from "@/views/mgmt/place/Index.vue";
import MgmtPlaceAddEdit from "@/views/mgmt/place/AddEdit.vue";
import MgmtPlaceSearch from "@/views/mgmt/place/Search.vue";
import SearchAddress from "@/views/SearchAddress.vue";
import EstimatePrint from "@/views/estimate/Print.vue";
import EstimateDownload from "@/views/estimate/Download.vue";
import EventAccessLog from "@/views/event/AccessLog.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import DashboardEstimateMore from "@/views/dashboard/EstimateMore.vue";
import DashboardScheduleMore from "@/views/dashboard/ScheduleMore.vue";
import Support from "@/views/Support.vue";
import SupportAs from "@/views/SupportAs.vue";
import SupportView from "@/views/support/View.vue";
import SupportAsSearch from "@/views/support/as/Search.vue";
import SupportAsView from "@/views/support/as/View.vue";
import Schedule from "@/views/schedule/Index.vue";
import ScheduleDay from "@/views/schedule/Day.vue";
import ScheduleView from "@/views/schedule/View.vue";
import ScheduleTempView from "@/views/schedule/TempView.vue";
import StatusAddEdit from "@/views/status/AddEdit.vue";
import StatusView from "@/views/status/View.vue";
import ScheduleAddEdit from "@/views/schedule/AddEdit.vue";
import ScheduleSelect from "@/views/schedule/Select.vue";
import Exposition from "@/views/Exposition.vue";
import Company from "@/views/company/Company.vue";
import CompanyWaitingApproval from "@/views/company/CompanyWaitingApproval.vue";
import ScheduleFilter from "@/views/schedule/Filter.vue";
import ScheduleSearch from "@/views/schedule/Search.vue";
import Test from "@/views/Test.vue";
import EstimateCommentView from "@/views/estimate/CommentView.vue";
import AppInstall from "@/views/AppInstall.vue";
import UserMeWithdraw from "@/views/settings/UserMeWithdraw.vue";
import MapLink from "@/views/MapLink.vue";
import ExpositionEdit from "@/views/exposition/Edit.vue";
import SupportDownload from "@/views/support/SupportDownload.vue";

Vue.use(VueRouter);

const appName = Constant.appName;

export enum LayoutType {
  NAV = "nav",
  APP_BAR = "app_bar",
  APP_BAR_NAV = "app_bar_nav",
  APP_BAR_BACK = "app_bar_back",
}

export interface RouteMeta {
  title: string;
  roles: Role[] | null;
  companyRoles: CompanyRole[] | null | undefined;
  companyApprovalList: ApprovalType[] | null | undefined;
  layoutList: LayoutType[];
  pageStack: boolean;
  appBarMenu: AppBarMenu | null | undefined;
  scrollable: boolean | undefined;
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      title: "",
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "로그인",
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "회원가입",
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      title: "로그아웃",
      roles: [Role.ADMIN, Role.MANAGER, Role.USER],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "홈",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/notification",
    name: "notification",
    component: Notification,
    meta: {
      title: "알림",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/event/access-log",
    name: "eventAccessLog",
    component: EventAccessLog,
    meta: {
      title: "이벤트 접속 로그",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/event/list",
    name: "eventList",
    component: Event,
    meta: {
      title: "룰렛 이벤트",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/event/:type(add|edit)",
    name: "eventAdd",
    component: EventAddEdit,
    meta: {
      title: "룰렛 이벤트",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/event/:id(\\d+)",
    name: "eventView",
    component: EventView,
    meta: {
      title: "룰렛 이벤트",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
      appBarMenu: {
        more: {
          visible: true,
          width: 20,
          list: [
            {
              icon: "",
              text: "수정",
              id: "edit",
            },
            {
              icon: "",
              text: "삭제",
              id: "delete",
            },
          ],
        },
      } as AppBarMenu,
    } as RouteMeta,
  },
  {
    path: "/event/roulette",
    name: "roulette",
    component: EventRoulette,
    meta: {
      title: "이벤트 룰렛",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/event/roulette/winner/search",
    name: "eventRouletteWinnerSearch",
    component: EventRouletteWinnerSearch,
    meta: {
      title: "이벤트 당첨자 검색",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/event/special-price-link",
    name: "specialPriceLink",
    component: SpecialPriceLink,
    meta: {
      title: "특가표 링크 만들기",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      title: "설정",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/settings/user-info",
    name: "settingsUserMeInfo",
    component: UserMeInfo,
    meta: {
      title: "내 정보 수정",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW, ApprovalType.REQUEST, ApprovalType.DENY],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/settings/user-password",
    name: "settingsUserMePassword",
    component: UserMePassword,
    meta: {
      title: "비밀번호 변경",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW, ApprovalType.REQUEST, ApprovalType.DENY],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/settings/user-company",
    name: "settingsUserCompany",
    component: UserCompany,
    meta: {
      title: "업체 정보",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/settings/payment",
    name: "settingsPayment",
    component: Payment,
    meta: {
      title: "자동결제",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/settings/withdraw",
    name: "settingsWithdraw",
    component: UserMeWithdraw,
    meta: {
      title: "회원탈퇴",
      layoutList: [LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/mgmt/user",
    name: "mgmtUser",
    component: MgmtUser,
    meta: {
      title: "사용자",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
            },
          ],
        },
      } as AppBarMenu,
    } as RouteMeta,
  },
  {
    path: "/mgmt/user/search",
    name: "mgmtUserSearch",
    component: MgmtUserSearch,
    meta: {
      title: "사용자 검색",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/user/:id(\\d+)",
    redirect: "/mgmt/user/:id(\\d+)",
  },
  {
    path: "/mgmt/user/:id(\\d+)",
    name: "mgmtUserView",
    component: MgmtUserView,
    meta: {
      title: "사용자",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/mgmt/payment-sms",
    name: "mgmtPaymentSms",
    component: MgmtPaymentSms,
    meta: {
      title: "결제문자",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/payment-sms",
    name: "paymentSms",
    component: MgmtPaymentSms,
    meta: {
      title: "결제문자",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/mgmt/team",
    name: "mgmtTeam",
    component: MgmtTeam,
    meta: {
      title: "팀",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/mgmt/team/:id(\\d+)",
    name: "mgmtTeamView",
    component: MgmtTeamView,
    meta: {
      title: "팀",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/team/:type(add|edit)",
    name: "mgmtTeamAddEdit",
    component: MgmtTeamAddEdit,
    meta: {
      title: "팀",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/mgmt/category",
    name: "mgmtCategory",
    component: MgmtCategory,
    meta: {
      title: "품목",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/work-scope",
    name: "mgmtWorkScope",
    component: MgmtWorkScope,
    meta: {
      title: "작업범위",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/work-scope/:type(add|edit)",
    name: "mgmtWorkScopeAddEdit",
    component: MgmtWorkScopeAddEdit,
    meta: {
      title: "작업범위",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/mgmt/special-price",
    name: "mgmtSpecialPrice",
    component: MgmtSpecialPrice,
    meta: {
      title: "특별단가",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/special-price/:type(add|edit)",
    name: "mgmtSpecialPriceAddEdit",
    component: MgmtSpecialPriceAddEdit,
    meta: {
      title: "특별단가",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/mgmt/unit-price",
    name: "mgmtUnitPrice",
    component: MgmtUnitPrice,
    meta: {
      title: "단가",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
            },
          ],
        },
      } as AppBarMenu,
    } as RouteMeta,
  },
  {
    path: "/mgmt/unit-price/:type(add|edit)",
    name: "mgmtUnitPriceAddEdit",
    component: MgmtUnitPriceAddEdit,
    meta: {
      title: "단가",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/mgmt/unit-price/search",
    name: "mgmtUnitPriceSearch",
    component: MgmtUnitPriceSearch,
    meta: {
      title: "단가 검색",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/cancellation-policy",
    name: "mgmtCancellationPolicy",
    component: MgmtCancellationPolicy,
    meta: {
      title: "계약 취소 관련 유의사항",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: false,
      // appBarMenu: {
      //   icon: {
      //     visible: true,
      //     list: [
      //       {
      //         icon: "mdi-magnify",
      //         text: "검색",
      //         id: "search",
      //       },
      //     ],
      //   },
      // } as AppBarMenu,
    } as RouteMeta,
  },
  {
    path: "/mgmt/place",
    name: "mgmtPlace",
    component: MgmtPlace,
    meta: {
      title: "아파트",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
            },
          ],
        },
      } as AppBarMenu,
    } as RouteMeta,
  },
  {
    path: "/mgmt/place/:type(add|edit)",
    name: "mgmtPlaceAddEdit",
    component: MgmtPlaceAddEdit,
    meta: {
      title: "아파트",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/mgmt/place/search",
    name: "mgmtPlaceSearch",
    component: MgmtPlaceSearch,
    meta: {
      title: "아파트 검색",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/search-address",
    name: "searchAddress",
    component: SearchAddress,
    meta: {
      title: "주소검색",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "대시보드",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/dashboard/estimate-more",
    name: "dashboardEstimateMore",
    component: DashboardEstimateMore,
    meta: {
      title: "견적 더보기",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-circle-edit-outline",
              text: "수정",
              id: "edit",
            },
            {
              icon: "",
              text: "취소",
              id: "cancel",
            },
            {
              icon: "",
              text: "완료",
              id: "complete",
            },
            {
              icon: "mdi-download",
              text: "다운로드",
              id: "download",
            },
          ],
        },
      } as AppBarMenu,
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/dashboard/schedule-more",
    name: "dashboardScheduleMore",
    component: DashboardScheduleMore,
    meta: {
      title: "일정 더보기",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/support",
    name: "support",
    component: Support,
    meta: {
      title: "고객지원",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-download",
              text: "다운로드",
              id: "download",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/support/download",
    name: "supportDownload",
    component: SupportDownload,
    meta: {
      title: "고객지원 다운로드",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/support/:id(\\d+)",
    name: "supportView",
    component: SupportView,
    meta: {
      title: "고객지원",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
      scrollable: false,
      appBarMenu: {
        more: {
          visible: true,
          width: 20,
          list: [
            {
              text: "견적보기",
              id: "estimate",
            },
            {
              text: "메시지 복사",
              id: "copyMessage",
            },
            {
              text: "일정 추가",
              id: "addSchedule",
            },
            {
              text: "A/S 일정 추가",
              id: "addAsSchedule",
            },
            {
              text: "A/S 접수",
              id: "addAsReceipt",
            },
            {
              text: "처리완료",
              id: "complete",
            },
          ],
        },
      } as AppBarMenu,
    } as RouteMeta,
  },
  {
    path: "/support-as",
    name: "supportAs",
    component: SupportAs,
    meta: {
      title: "A/S 현황",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
            },
          ],
        },
      },
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/support/as/search",
    name: "supportAsSearch",
    component: SupportAsSearch,
    meta: {
      title: "A/S 현황 검색",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/support/as/:id(\\d+)",
    name: "supportAsView",
    component: SupportAsView,
    meta: {
      title: "A/S 현황",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,

      appBarMenu: {
        more: {
          visible: true,
          width: 20,
          list: [
            {
              text: "A/S 접수 확인 처리",
              id: "receipt",
              clazz: "orange--text",
            },
            {
              text: "A/S 일정 추가",
              id: "addAs",
            },
            {
              text: "A/S 취소 처리",
              id: "cancelAs",
              clazz: "red--text",
            },
          ],
        },
      },
    },
  },
  {
    path: "/estimate",
    name: "estimate",
    component: Estimate,
    meta: {
      title: "견적",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,

      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
            },
          ],
        },
        more: {
          visible: true,
          width: 20,
          list: [
            {
              icon: "mdi-sort",
              text: "정렬",
              id: "sort",
            },
            {
              icon: "mdi-download",
              text: "다운로드",
              id: "download",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/estimate-counsel",
    name: "estimateCounsel",
    component: CounselEstimate,
    meta: {
      title: "상담내역",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,

      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-download",
              text: "다운로드",
              id: "download",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/estimate-counsel/download",
    name: "counselEstimateDownload",
    component: CounselEstimateDownload,
    meta: {
      title: "상담내역 다운로드",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/estimate/:id(\\d+)/print",
    name: "estimatePrint",
    component: EstimatePrint,
    meta: {
      title: "견적서 출력",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/estimate/:id(\\d+)/comment",
    name: "estimateComment",
    component: EstimateCommentView,
    meta: {
      title: "댓글",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
      scrollable: false,
    } as RouteMeta,
  },
  {
    path: "/estimate/download",
    name: "estimateDownload",
    component: EstimateDownload,
    meta: {
      title: "견적서 다운로드",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/estimate/search",
    name: "estimateSearch",
    component: EstimateSearch,
    meta: {
      title: "견적 검색",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/estimate/:id(\\d+)",
    name: "estimateView",
    component: EstimateView,
    meta: {
      title: "견적",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/estimate/:type(add|edit)",
    name: "estimateAddEdit",
    component: EstimateAddEdit,
    meta: {
      title: "견적",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              text: "특가표",
              id: "specialPrice",
              visible: false,
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/schedule/:type(all|default|vacation|attendance|category-\\d+|team-\\d+)",
    name: "schedule",
    component: Schedule,
    meta: {
      title: "일정",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
      scrollable: false,
      appBarMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-download",
              text: "다운로드",
              id: "download",
            },
            {
              icon: "mdi-filter-variant",
              text: "필터",
              id: "filter",
              badge: false,
              badgeContent: "",
            },
            {
              icon: "mdi-magnify",
              text: "검색",
              id: "search",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/app-install",
    name: "appInstall",
    component: AppInstall,
    meta: {
      title: "앱 설치",
      roles: null,
      layoutList: [LayoutType.APP_BAR],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/schedule/:type(all|default|vacation|attendance|category-\\d+|team-\\d+)/search",
    name: "scheduleSearch",
    component: ScheduleSearch,
    meta: {
      title: "검색",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/schedule/:type(all|default|vacation|attendance|category-\\d+|team-\\d+)/filter",
    name: "scheduleFilter",
    component: ScheduleFilter,
    meta: {
      title: "일정 필터",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/schedule/day",
    name: "scheduleDay",
    component: ScheduleDay,
    meta: {
      title: "일정",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/schedule/:id(\\d+)",
    name: "scheduleView",
    component: ScheduleView,
    meta: {
      title: "일정",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
      scrollable: false,
      appBarMenu: {
        more: {
          visible: false,
          width: 20,
          list: [
            {
              text: "상단 고정",
              id: "topFixedTrue",
            },
            {
              text: "상단 고정 해제",
              id: "topFixedFalse",
            },
            {
              text: "견적 보기",
              id: "estimate",
            },
            {
              text: "일정 수정",
              id: "edit",
            },
            {
              text: "메모 수정",
              id: "editMemo",
            },
            {
              text: "일정 삭제",
              id: "delete",
            },
            {
              text: "A/S 추가",
              id: "addAs",
            },
            {
              text: "일정 문자 전송",
              id: "sendSmsSchedule",
            },
            {
              text: "담당자 지정",
              id: "editUser",
            },
            {
              text: "시공완료 취소",
              id: "cancelScheduleComplete",
            },
          ],
        },
      } as AppBarMenu,
    } as RouteMeta,
  },
  {
    path: "/schedule/temp/:id(\\d+)",
    name: "scheduleTempView",
    component: ScheduleTempView,
    meta: {
      title: "일정",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/schedule/:type(add|edit|as)",
    name: "scheduleAddEdit",
    component: ScheduleAddEdit,
    meta: {
      title: "일정",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: true,
    },
  },
  {
    path: "/schedule/select",
    name: "scheduleSelect",
    component: ScheduleSelect,
    meta: {
      title: "일정 선택",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV],
      pageStack: false,
    },
  },
  {
    path: "/status/:type(add|edit)",
    name: "statusAddEdit",
    component: StatusAddEdit,
    meta: {
      title: "시공현황",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/status/:id(\\d+)",
    name: "statusView",
    component: StatusView,
    meta: {
      title: "시공현황",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
      appBarMenu: {
        more: {
          visible: true,
          width: 20,
          list: [
            {
              id: "edit",
              text: "수정",
            },
            {
              id: "download",
              text: "다운로드",
            },
          ],
        },
      },
    } as RouteMeta,
  },
  {
    path: "/exposition",
    name: "exposition",
    component: Exposition,
    meta: {
      title: "박람회 소개",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [] as LayoutType[],
      pageStack: false,
      scrollable: false,
    } as RouteMeta,
  },
  {
    path: "/exposition/edit",
    name: "expositionEdit",
    component: ExpositionEdit,
    meta: {
      title: "박람회 소개 수정",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [] as LayoutType[],
      pageStack: false,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/company",
    name: "company",
    component: Company,
    meta: {
      title: "업체",
      roles: [Role.ADMIN, Role.MANAGER],
      // companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.COUNSELOR, CompanyRole.USER],
      // companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [] as LayoutType[],
    } as RouteMeta,
  },
  {
    path: "/company/waiting-approval",
    name: "companyWaitingApproval",
    component: CompanyWaitingApproval,
    meta: {
      title: "업체 승인 대기중",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [CompanyRole.USER],
      companyApprovalList: [ApprovalType.REQUEST, ApprovalType.DENY],
      layoutList: [] as LayoutType[],
    },
  },
  {
    path: "/notice/list",
    name: "noticeList",
    component: Notice,
    meta: {
      title: "공지",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_NAV],
      pageStack: true,
    } as RouteMeta,
  },
  {
    path: "/notice/:id(\\d+)",
    name: "noticeView",
    component: NoticeView,
    meta: {
      title: "공지",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
        CompanyRole.USER,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  // {
  //   path: "/company/add",
  //   name: "companyAdd",
  //   component: CompanyAdd,
  //   meta: {
  //     title: "인하우스 - 업체 등록",
  //     roles: ["manager"],
  //     companyOptions: [],
  //     pageStack: false,
  //   },
  // },
  // {
  //   path: "/company/register",
  //   name: "companyRegister",
  //   component: CompanyRegister,
  //   meta: {
  //     title: "인하우스 - 업체 멤버로 등록",
  //     roles: ["manager"],
  //     companyOptions: [],
  //     pageStack: false,
  //   },
  // },
  {
    path: "/test",
    name: "test",
    component: Test,
    meta: {
      title: "테스트",
      roles: [Role.ADMIN, Role.MANAGER],
      companyRoles: [
        CompanyRole.OWNER,
        CompanyRole.ADMIN,
        CompanyRole.MANAGER,
        CompanyRole.COUNSELOR,
      ],
      companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.NAV, LayoutType.APP_BAR_BACK] as LayoutType[],
      scrollable: false,
    },
  },
  {
    path: "/map-link",
    name: "mapLink",
    component: MapLink,
    meta: {
      title: "지도 링크",
      // roles: [Role.ADMIN, Role.MANAGER],
      // companyRoles: [CompanyRole.OWNER],
      // companyApprovalList: [ApprovalType.ALLOW],
      layoutList: [LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: {
      title: "지원하지 않는 브라우저 입니다",
      roles: null,
      pageStack: false,
    } as RouteMeta,
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      title: "페이지를 찾을 수 없습니다",
      roles: null,
      layoutList: [LayoutType.APP_BAR_BACK],
      pageStack: false,
    } as RouteMeta,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }

  if (data.meta != null) {
    // appBarMenu 초기값 설정
    const appBarMenu = (data.meta as RouteMeta).appBarMenu;
    if (appBarMenu) {
      ["icon", "more"].forEach((key) => {
        if (appBarMenu[key]) {
          const list = appBarMenu[key].list;
          if (list != null) {
            list.forEach((menu) => {
              [
                { key: "visible", value: true },
                { key: "clickEvent", value: false },
              ].forEach((defaultColumn) => {
                if (menu[defaultColumn.key] == undefined) {
                  menu[defaultColumn.key] = defaultColumn.value;
                }
              });
            });
          }
        }
      });
    }
  }
});

async function routerResult(next, path: string): Promise<RouteMeta | null> {
  next({ path: path });
  const route = routeMap.get(path);
  // console.log("routerResult path : ", path);
  if (route) {
    return route.meta;
  }
  return routeMap.get("*").meta;
}

async function beforeEach(to, from, next): Promise<RouteMeta | null> {
  const user = (await store.getters["auth/user"]()) as UserModel;
  if (user) {
    if (to.path === "/login") {
      return await routerResult(next, "/");
    }

    // 로그인 중인 사용자
    if (to.meta.roles != null && to.meta.roles.length > 0) {
      let accessible = false;
      user.roles.some((role) => {
        if (to.meta.roles.indexOf(role) > -1) {
          accessible = true;
          return true;
        }
      });

      if (!accessible) {
        return await routerResult(next, "/");
      }

      if (user.company) {
        if (to.meta.companyRoles != null && to.meta.companyRoles.length > 0) {
          accessible = false;
          to.meta.companyRoles.some((companyRole) => {
            if (user.company.role === companyRole) {
              accessible = true;
              return true;
            }
          });
          if (!accessible) {
            return await routerResult(next, "/");
          }
        }

        if (to.meta.companyApprovalList) {
          accessible = false;
          to.meta.companyApprovalList.some((companyApproval) => {
            if (user.company.lastApproval.type === companyApproval) {
              accessible = true;
              return true;
            }
          });
          if (accessible) {
            next();
            return to.meta;
          }
        }

        if (user.company.lastApproval.type !== ApprovalType.ALLOW) {
          if (to.path === "/logout") {
            next();
            return to.meta;
          } else if (to.path !== "/company/waiting-approval") {
            return await routerResult(next, "/company/waiting-approval");
          }
        } else if (to.path === "/company") {
          return await routerResult(next, "/");
        }
      } else {
        if (to.path !== "/company") {
          return await routerResult(next, "/company");
        }
      }
      next();
    } else {
      // 비로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.path.toLowerCase() === "/login") {
        if (core.utils.validate.isBlank(to.query.v)) {
          // 로그인중인 사용자가 로그인 페이지 접근시 이동
          return await routerResult(next, "/home");
        } else {
          // 로그인중인 사용자가 자동인증 정보파라미터를 포함해서 들어왔을 경우 로그인 페이지로 이동
          next();
        }
      } else {
        next();
      }
      // console.log("로그인 사용자 next, 페이지 권한 필요없음");
    }
  } else {
    if (to.meta.roles != null && to.meta.roles.length > 0) {
      // 로그인 사용자 접근 가능 페이지일 경우 메인 페이지로 이동
      return await routerResult(next, "/login");
    } else {
      next();
      // console.log("비로그인 사용자 next, 페이지 권한 필요없음");
    }
  }

  return to.meta;
}

function hasLayout(meta: RouteMeta, ...paramLayoutTypes) {
  if (meta.layoutList != null) {
    let exists = false;
    paramLayoutTypes.some((paramLayoutType) => {
      (meta.layoutList as LayoutType[]).some((layoutType) => {
        if (paramLayoutType === layoutType) {
          exists = true;
          return exists;
        }
      });
      if (exists) {
        return exists;
      }
    });
    return exists;
  }
  return false;
}

let first = true;
let overflow = true;
router.beforeEach(async (to, from, next) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);
  // console.log("from " + from.path + " -> to : " + to.path);
  if (first) {
    first = false;
    core.preloader.show();
  }

  if (store.state.topToolbar.changedStyle) {
    await store.dispatch("topToolbar/changeOrgStyle");
  }

  if (core.toast.isShow()) {
    core.toast.hide();
  }

  if (
    (from.path === "/home" && to.path === "/login") ||
    (from.path === "/login" && to.path === "/login")
  ) {
    window.history.back();
    return;
  }

  const meta = await beforeEach(to, from, next);
  if (meta != null) {
    if (meta.title != null) {
      if (meta.title.length > 0) {
        document.title = `${appName} - ${meta.title}`;
      } else {
        document.title = appName;
      }
    }

    // if (hasLayout(meta, LayoutType.APP_BAR, LayoutType.APP_BAR_NAV, LayoutType.APP_BAR_BACK)) {
    //   await store.dispatch("topToolbar/changeTitle", { title: meta.title, ignoreCheck: false });
    // }
    await store.dispatch("topToolbar/changeTitle", { title: meta.title, ignoreCheck: false });

    if (meta.scrollable !== undefined) {
      if (meta.scrollable) {
        overflow = true;
        document.documentElement.classList.remove("scc-scrollbar-hidden");
      } else {
        overflow = false;
        document.documentElement.classList.add("scc-scrollbar-hidden");
      }
    } else if (!overflow) {
      document.documentElement.classList.remove("scc-scrollbar-hidden");
    }
  }

  if (core.preloader.isShow()) {
    core.preloader.hide();
  }
});

export default router;
