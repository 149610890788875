
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import core from "@/core";
import store from "@/store";
import SmsService from "@/services/sms.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    leaderName: "팀장명 ",
    teamCount: "팀원수 ",
    cleaning1: "외창청소 ",
    cleaning2: "에어컨 필터청소(1대) ",
    cleaning3: "붙박이장 10~12자 ",
    cleaning4: "붙박이장 3~4자 ",
    content: "문자내용 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "send-sms-schedule-cleaning-modal",
  data: () => ({
    ready: false,
    schedule: null as any,
    form: {
      leaderName: "",
      teamCount: "",
      cleaning1: "",
      cleaning2: "",
      cleaning3: "",
      cleaning4: "",
      content: "",
    },
    app: store.state.app,
    bodyMinHeight: "100px",
  }),
  mounted() {
    this.$nextTick(async () => {
      this.resize();

      const schedule = (this.schedule = this.properties.params.schedule);
      schedule.userList.forEach((user) => {
        if (user.type === "MAIN") {
          this.form.leaderName = user.name;
        }
      });
      this.ready = true;
      this.updateContent();

      console.log("schedule : ", this.schedule);
    });
  },
  watch: {
    "app.size"() {
      this.resize();
    },
    "form.leaderName"() {
      this.updateContent();
    },
    "form.teamCount"() {
      this.updateContent();
    },
    "form.cleaning1"() {
      this.updateContent();
    },
    "form.cleaning2"() {
      this.updateContent();
    },
    "form.cleaning3"() {
      this.updateContent();
    },
    "form.cleaning4"() {
      this.updateContent();
    },
  },
  methods: {
    resize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 200;
      } else {
        if (this.app.size.width < 965) {
          offset = 215;
        } else {
          offset = 220;
        }
      }
      this.bodyMinHeight = this.app.size.height - offset + "px";
    },
    updateContent() {
      if (this.ready) {
        const form = this.form;
        const schedule = this.schedule;
        const estimate = schedule.estimate;
        let msg = "";
        msg += `안녕하세요 ${estimate.dong}동 ${estimate.ho}호 고객님 인하우스 청소팀 ${form.leaderName} 팀장 입니다\n`;
        msg += `\n먼저 입주를 진심으로 축하드립니다.\n`;
        const strStartAt = core.date.instance(schedule.startAt).format("MM.DD(ddd) a hh:mm");
        const strEndAt = core.date.instance(schedule.startAt).format("a hh:mm");
        msg += `\n고객님 댁은 ${estimate.type} 타입으로 저 포함 팀원 총 ${form.teamCount}명이 ${strStartAt}에 투입되어  ${strEndAt}까지 청소를 마칠 예정입니다.\n`;

        msg += `\n입주청소 시행 전 몇가지 안내사항을 전달드리고 진행하도록 하겠습니다.\n`;
        msg += `\n1. 하자 부분입니다 고객님께서 사전점검시 꼼꼼히 하자체크를 한다고 하셨어도 지저분한 상태에서 확인 하신거라서 청소 중 또는 청소 후에 발견 되는 하자가 빈번히 발생하고 있으며 청소시 하자가 발견될 경우 사진으로 전송해드리겠습니다\n`;
        msg += `\n2. 현관문 비닐 제거에 관한 부분입니다.`;
        msg += `\n고객님 요청사항에 따라 제거해드릴 수는 있지만 문에 보양 박스와 비닐을 제거하면 뜯기전에는 안보였던 스크래치가 있는 경우가 종종 있습니다. 이 부분에 있어서도 문제가 있으면 사진으로 전송해드리며 청소 후에도 이사짐 등이 들어오면서  스크래치가 발생될 수 있으니 이사후에 이사짐 센터 또는 고객님께서 직접 제거하시는 것을 추천드립니다.\n`;
        msg += `\n3. 청소 작업전에 문제가 있는 부분은 사전에 사진 및 통화로 공지해드리고 시공후에도 사진을 전송해드리는 시스템으로 운영을 하고 있습니다\n`;
        msg += `\n4. 입주 청소에 관한 부분입니다. 청소시 외창 청소는 안전상의 이유로 제외하며 먼지는 바닥,벽,천장몰딩 부분까지 제거를 기본으로 하고 있습니다.`;
        msg += `\n그러나 분진은 150마이크로미터로 어떠한 청소도구를 사용해도 한번에 닦이지 않는게 현실입니다. 이 부분에 있어서는 그 어떤 청소업체도 한번에 해결되지 않는 부분입니다. 청소후에도 2~3주간은 공기중에 있던 먼지나 분진이 계속 발생 될수 있으니 참고 부탁드립니다.\n`;
        msg += `\n5. 추가요금 발생 건 입니다`;
        msg += `\n추가요금 발생은 외창 청소, 에어컨 필터 청소, 붙박이장 추가 설치시 발생되는 부분입니다. 기존 붙박이장 1개는 내부까지 청소를 해드리지만 그 외에는 내부 청소를 원하시면 추가비용이 발생되며 현장에서 팀장님과 협의 후 따로 지불하시면 됩니다.\n`;

        const price = {
          cleaning1: "",
          cleaning2: "",
          cleaning3: "",
          cleaning4: "",
        } as any;

        if (this.isNotBlank(form.cleaning1)) {
          price.cleaning1 = core.utils.format.money(String(Number(form.cleaning1) * 10000));
        }
        if (this.isNotBlank(form.cleaning2)) {
          price.cleaning2 = core.utils.format.money(String(Number(form.cleaning2) * 10000));
        }
        if (this.isNotBlank(form.cleaning3)) {
          price.cleaning3 = core.utils.format.money(String(Number(form.cleaning3) * 10000));
        }
        if (this.isNotBlank(form.cleaning4)) {
          price.cleaning4 = core.utils.format.money(String(Number(form.cleaning4) * 10000));
        }

        msg += `\n☆외창청소(${price.cleaning1}원) 에어컨 필터청소 대당 (${price.cleaning2}원) 붙박이장 10~12자(${price.cleaning3}원) 3~4자(${price.cleaning4}원)\n`;
        msg += `\n마지막으로 리터치 제도입니다`;
        msg += `\n한번에 깔끔한 청소로 마무리하는게 당사와 고객님 모두가 행복하지만 사람이 하는 일이라 미흡한 부분이 생길 수 있으며 시공범위 내에서 빠진 부분이 있다면 3일이내에 재방문하여 미흡한 부분을 다시 청소해드리는 방식으로 운영을 하고 있습니다`;
        msg += `\n고객님 입주를 진심으로 축하드리고 이댁에서 늘 건강하시고 행복하며 부자 되시길 기원하겠습니다.`;
        msg += `\n인하우스를 믿고 맡겨 주신만큼 최선을 다해 내집처럼 청소를 해드리겠습니다.`;
        msg += `\n지금까지 인하우스 팀장 ${form.leaderName}였습니다.`;
        this.form.content = msg;
      }
    },
    async submit() {
      console.log("submit");
      if (await this.validate()) {
        try {
          core.loader.show("문자 전송중...");
          const params = {
            receiver: this.schedule.estimate.customerPhone,
            msg: this.form.content,
          };
          const smsPushId = await SmsService.createSms(params);
          const interval = setInterval(async () => {
            try {
              const apiResult = (await SmsService.getSmsResult(smsPushId as string)) as any;
              if (apiResult.result) {
                clearInterval(interval);
                core.loader.hide();
                await core.alert.show({
                  title: "알림",
                  body: "문자 전송 완료",
                });
              } else if (apiResult.code < 0) {
                clearInterval(interval);
                core.loader.hide();
                await core.alert.show({
                  title: "알림",
                  body: apiResult.message,
                });
              }
            } catch (e: any) {
              console.log(e);
              await core.alert.show({
                title: "알림",
                body: e.message,
              });
            }
          }, 2000);
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
});
