import korea from "vee-validate/dist/locale/ko.json";
import core from "@/core";

class Constant {
  appName = "인하우스";
  validate = {
    language: {
      korea: korea,
    },
  };

  managerUrl = "https://manager.smart-line.co.kr";
  customerUrl = "https://customer.smart-line.co.kr";
  eventUrl = "https://event.smart-line.co.kr";

  kakao = {
    javascriptKey: "9c6ae8f438f92f360dcb6be3dcf43764",
    restApiKey: "8a4dbcef128af44068e1b550873624e1",
  };

  viewerOptions = {
    inline: false,
    button: true,
    navbar: true,
    title: false,
    toolbar: {
      zoomIn: true,
      zoomOut: true,
      oneToOne: true,
      reset: true,
      rotateLeft: true,
      rotateRight: true,
      flipHorizontal: true,
      flipVertical: true,
      prev: true,
      play: true,
      next: true,
      download: async (e) => {
        const viewer = document.querySelector(
          ".viewer-container.viewer-in .viewer-canvas img"
        ) as any;
        if (viewer) {
          try {
            await core.http.downloadFile(viewer.src);
          } catch (e) {
            console.log(e);
          }
        }
      },
    },
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: true,
    fullscreen: true,
    keyboard: true,
  };

  iosStoreUrl = "https://apps.apple.com/kr/app/id6446343053";
}

export default new Constant();
