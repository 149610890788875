
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import { UpdateEventType } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import TextFieldModal from "@/modals/core/TextFieldModal.vue";
import UpdateEvent from "@/models";
import TextAreaModal from "@/modals/core/TextAreaModal.vue";
import CautionService from "@/services/caution/caution.service";
import core from "@/core";
import { PlaceModel } from "@/models/place/place.model";

export default mixins(MixinsPage, MixinsTable, MixinsScroll, MixinsAppBarMenu).extend({
  name: "MgmtCancellationPolicy",
  components: { TextAreaModal, TextFieldModal },
  data: () => ({
    // table: {
    //   body: document.documentElement,
    //   defaultColumns: [],
    //   firstLoading: true,
    //   loading: false,
    // },
    text: "",
    modal: {
      text: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 1000,
          },
          title: "계약 취소 관련 유의사항",
          type: "edit",
          text: "",
          item: null as any,
          enableDeleteButton: true,
        },
        updateEvent: null as UpdateEvent | null,
      },
      place: {
        visible: false,
      },
    },
    place: {
      list: [] as PlaceModel[],
      filterList: [] as PlaceModel[],
      cautionPlaceMap: {} as { [key: string]: PlaceModel },
      cautionPlaceList: [] as PlaceModel[],
      selected: null as PlaceModel | null,
    },
  }),
  mounted() {
    // this.initTable({
    //   service: PlaceService,
    //   serviceFunctionKey: "getTable",
    //   itemHeight: 60,
    // });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      // this.loadTable();

      try {
        const caution = (await CautionService.get()) as any;
        // console.log("caution : ", caution);
        this.text = caution.caution;
      } catch (e) {
        console.log(e);
      }

      try {
        const placeList = (await CautionService.getPlaceList()) as any;
        placeList.forEach((item) => {
          this.place.cautionPlaceList.push(item);
        });
        console.log("placeList : ", placeList);
      } catch (e) {
        console.log(e);
      }
      // const placeMap = {} as any;
      // const placeList = await this.$store.getters["app/getPlaceList"]();
      // placeList.forEach((place) => {
      //   placeMap[place.id] = place;
      // });
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "place.cautionPlaceList"(placeList) {
      console.log("placeList : ", placeList);
      if (placeList != null) {
        const cautionPlaceMap = {} as any;
        placeList.forEach((place) => {
          cautionPlaceMap[place.id] = place;
        });
        this.place.cautionPlaceMap = cautionPlaceMap;
      } else {
        const cautionPlaceMap = {} as any;
        this.place.cautionPlaceMap = cautionPlaceMap;
      }
    },
    async "modal.text.updateEvent"() {
      const modal = this.modal.text;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const content = event.item;
          // console.log("content : ", content);
          const params = {
            caution: content,
          };
          const caution = (await CautionService.update(params)) as any;
          // console.log("caution : ", caution);
          this.text = caution.caution;
        } else if (event.result === UpdateEventType.DELETE) {
          try {
            console.log("delete");
            (await CautionService.delete()) as any;
            this.text = "";
          } catch (e) {
            console.log(e);
          }
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    async "place.selected"(place) {
      if (place != null) {
        const addPlace = (await CautionService.addPlace(place.id)) as any;
        console.log("addPlace : ", addPlace);
        this.place.cautionPlaceList.push(addPlace);
        place.selected = null;
      }
    },
  },
  methods: {
    textToHtml(text: string) {
      return core.utils.format.textToHtml(text);
    },
    showEditTextModal() {
      const modal = this.modal.text;
      modal.params.text = this.text;
      modal.visible = true;
    },
    async showAddPlaceModal() {
      //console.log("showAddPage");
      const modal = this.modal.place;
      if (this.place.list.length === 0) {
        // const placeMap = {} as any;
        const placeList = await this.$store.getters["app/getPlaceList"]();
        placeList.forEach((place) => {
          // placeMap[place.id] = place;
          this.place.list.push(place);
        });
      }
      const placeList = [] as PlaceModel[];
      this.place.list.forEach((place) => {
        if (this.place.cautionPlaceMap[place.id] == null) {
          placeList.push(place);
        }
      });
      this.place.filterList = placeList;
      modal.visible = true;
    },
    async deletePlace(place: PlaceModel) {
      try {
        const result = await core.alert.show({
          title: "확인",
          body: `"${place.name}" 아파트 정보를 삭제하시겠습니까?<br>삭제하시면 더이상 해당 아파트에서는 "계약 취소 관련 유의사항"이 표시되지 않습니다.`,
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });

        if (result === "confirm") {
          await CautionService.deletePlace(place.id);
          delete this.place.cautionPlaceMap[place.id];
          this.place.cautionPlaceList.some((item, index) => {
            if (item.id == place.id) {
              this.place.cautionPlaceList.splice(index, 1);
              return true;
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
});
