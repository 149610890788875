import core from "@/core";

class CautionService {
  update(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/caution`, strParams)
        .then((data: any) => {
          if (data.caution != null) {
            resolve(data.caution);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete() {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/caution`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  get() {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/caution`, { ignoreAlertModal: true })
        .then((data: any) => {
          if (data.caution != null) {
            resolve(data.caution);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getByPlaceId(placeId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/caution/place/${placeId}`, { ignoreAlertModal: true })
        .then((data: any) => {
          if (data.caution != null) {
            resolve(data.caution);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getPlaceList() {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/caution/place`, { ignoreAlertModal: true })
        .then((data: any) => {
          if (data.placeList != null) {
            resolve(data.placeList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
  addPlace(placeId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/caution/place/${placeId}`, "")
        .then((data: any) => {
          if (data.place != null) {
            resolve(data.place);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  deletePlace(placeId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/caution/place/${placeId}`)
        .then((data: any) => {
          if (data != null) {
            resolve(data);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new CautionService();
